import { Link } from 'gatsby'
import React from 'react'
import { rhythm } from '../utils/typography'
import Typed from './Typed'

const PostNav = ({ pageContext }) => {
  const { previous, up, next } = pageContext

  return (
    <>
      <hr style={{ marginTop: rhythm(1) }} />
      <ul
        style={{
          display: `flex`,
          flexWrap: `wrap`,
          justifyContent: `space-between`,
          listStyle: `none`,
          padding: 0,
          margin: 0,
        }}
      >
        {previous && (
          <li style={{ marginRight: rhythm(1) }}>
            <Typed ele={Link} to={previous.fields.slug} rel="prev">
              {`← ${previous.frontmatter.title || previous.frontmatter.date}`}
            </Typed>
            {previous.frontmatter.title && (
              <small style={{ display: 'block', textAlign: 'right' }}>
                {previous.frontmatter.date}
              </small>
            )}
          </li>
        )}
        {up && (
          <li>
            <Typed ele={Link} to={up.fields.slug}>
              {`↑ ${up.frontmatter.title}`}
            </Typed>
          </li>
        )}
        {next && (
          <li style={{ marginLeft: rhythm(1) }}>
            <Typed ele={Link} to={next.fields.slug} rel="next">
              {`${next.frontmatter.title || next.frontmatter.date} →`}
            </Typed>
            {next.frontmatter.title && (
              <small style={{ display: 'block' }}>
                {next.frontmatter.date}
              </small>
            )}
          </li>
        )}
      </ul>
    </>
  )
}

export default PostNav
