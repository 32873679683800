import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import Comments from '../components/comments'
import Layout from '../components/Layout'
import PostInfo from '../components/PostInfo'
import PostNav from '../components/PostNav'
import SEO from '../components/seo'

const MusingPostTemplate = ({ data: { mdx: post }, pageContext }) => {
  return (
    <Layout>
      <SEO title={post.frontmatter.date} description={post.excerpt} />
      <PostInfo post={post} />
      <MDXRenderer>{post.body}</MDXRenderer>
      <PostNav pageContext={pageContext} />
      <Comments slug={post.fields.slug} status={post.frontmatter.comments}/>
    </Layout>
  )
}

export default MusingPostTemplate

export const pageQuery = graphql`
  query MusingPostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      frontmatter {
        date: date(formatString: "MMMM Do, YYYY, h:mm A")
        tags
        comments
      }
      body
      excerpt
      fields {
        slug
        tagSlugs
      }
    }
  }
`
